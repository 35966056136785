import './App.scss';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CashflowViewWrapper } from './mian/CashflowViewWrapper/CashflowViewWrapper.component';

require('typeface-nunito')
dayjs.extend(relativeTime);

function App() {
	return (
		<div className="App">

			<CashflowViewWrapper />

			{/*<div>*/}
			{/*	<pre>{JSON.stringify(data, null, 2)}</pre>*/}
			{/*</div>*/}
		</div>
	);
}

export default App;
