import { v4 as uuid } from 'uuid';
import { rootAccount, WORLD } from '../../domain/account/Accounts.constants';
import { BudgetData } from "../../domain/budget/BudgetData.types";
import { CalculationConfig } from "../../domain/calculationConfig/CalculationConfig.type";
import { DayOfMonth } from "../../domain/date/DayOfMonth.constants";
import { toLocalDate } from "../../domain/date/LocalDate.types";
import { EntrySource } from "../../domain/transaction/Transaction.types";
import {
	bigExpensesAccount, bigExpensesAccountId, carLoanAccount, carLoanAccountId, homeLoanAccount, homeLoanAccountId, itAccount, itAccountId, milleniumAccount, milleniumAccountId, monthlyFeesAccount, monthlyFeesAccountId, pekaoAccount, pekaoAccountId, rentalAccount, rentalAccountId
} from "./ExampleAccount.constants";
import { holidays } from "./ExampleHolidays.constants";
import { cyclicIncomes, generateIncomes, incomeWorkingDaysProfileId } from './ExampleIncomes.constants';
import { cyclicOutcomes, outcomesFebruary, outcomesJanuary } from './ExampleOutcomes.constants';


const calculationConfig: CalculationConfig =
	{
		workingDaysProfiles: [
			{
				id: incomeWorkingDaysProfileId,
				personalHolidays: [toLocalDate('2022-01-24'), toLocalDate('2022-01-25'), toLocalDate('2022-01-26'), toLocalDate('2022-01-27'), toLocalDate('2022-01-28')],
				publicHolidays: holidays,
				businessDays: [DayOfMonth.MONDAY, DayOfMonth.TUESDAY, DayOfMonth.WEDNESDAY, DayOfMonth.THURSDAY, DayOfMonth.FRIDAY],
			},
		],

		subAccounts: {
			rootAccountId: [pekaoAccountId, milleniumAccountId],
			pekaoAccountId: [rentalAccountId, monthlyFeesAccountId, homeLoanAccountId],
			milleniumAccountId: [itAccountId, carLoanAccountId, bigExpensesAccountId],
		},
	};

export const data: BudgetData = {
	calculationConfig: calculationConfig,
	accounts: [rootAccount, pekaoAccount, milleniumAccount, rentalAccount, itAccount, monthlyFeesAccount, homeLoanAccount, carLoanAccount, bigExpensesAccount],
	transactions: [
		...generateIncomes('2022-01-01'),
		...generateIncomes('2022-02-01'),
		...generateIncomes('2022-03-01'),
		...generateIncomes('2022-04-01'),
		...generateIncomes('2022-05-01'),
		...generateIncomes('2022-06-01'),
		...generateIncomes('2022-07-01'),
		...generateIncomes('2022-08-01'),
		...generateIncomes('2022-09-01'),
		...generateIncomes('2022-10-01'),
		...generateIncomes('2022-11-01'),
		...generateIncomes('2022-12-01'),
		...outcomesJanuary,
		...outcomesFebruary,
		{
			id: uuid(),
			label: 'Any expense',
			date: '2022-08-01',
			accountFrom: monthlyFeesAccountId,
			accountTo: WORLD,
			value: 31500.,
			entrySource: EntrySource.MANUAL,
		},
	],
	cyclicTransactions: [
		...cyclicIncomes,
		...cyclicOutcomes,
	],
}
