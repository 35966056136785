import React from 'react';
import {Line} from 'react-chartjs-2';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

import {ChartSeries} from "../../domain/chart/Chart.type";

import styles from "./TransactionChart.module.scss";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
        },
    },
    // interaction: {
    //     intersect: false,
    // },
    // scales: {
    //     x: {
    //         display: true,
    //         title: {
    //             display: true
    //         }
    //     },
    //     y: {
    //         display: true,
    //         title: {
    //             display: true,
    //             text: 'Value'
    //         },
    //         suggestedMin: -10,
    //         suggestedMax: 200
    //     }
    // }
};

const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


const otherColors = [

    /*green*/
    'rgb(89,152,26)',
    'rgb(236,248,127)',
    'rgb(129,182,34)',
    'rgb(61,85,12)',
    'rgb(148,201,115)',
    'rgb(47,82,51)',
    'rgb(177,216,183)',
    'rgb(118,185,71)',

    /*red*/
    'rgb(255,127,80)',
    'rgb(255,93,34)',
    'rgb(243,65,0)',
    'rgb(197,53,0)',
    'rgb(151,41,0)',
    'rgb(106,28,0)',
    'rgb(60,16,0)',

    /*red/sinopia*/'rgba(199, 62, 29, 0.65)',
    /*green*/'rgba(42, 145, 52, 0.65)',
    /*satin sheen gold*/'rgba(197, 152, 73, 0.65)',
    /*celadon*/'rgba(166, 212, 159, 0.65)',

    'rgba(255, 99, 132, 0.65)',
    'rgba(53, 162, 235, 0.65)',
]

interface ComeLineChartProps {
    series: ChartSeries[],
}

const getAdditiveComes = (comes: number[]): number[] => {
    if (!comes) {
        return [];
    }
    return comes
        .reduce((prev: number[], cur: number): number[] => {
            const lastValue = (prev.length && prev[prev.length - 1]) || 0;
            return [...prev, (cur + lastValue)];
        }, [] as number[])
}

export const ComeLineChart: React.FC<ComeLineChartProps> = (props: ComeLineChartProps) => {
    const {series} = props;

    const chartData = {
        labels: monthLabels,
        datasets: series.map((s, index) => ({
            label: s.label,
            data: getAdditiveComes(s.data).map(value => Number.parseFloat(value.toFixed(2))),
            backgroundColor: otherColors[index],
        })),
    };

    return (
        <div className={styles.card}>
            Chart

            <Line options={options} data={chartData}/>
        </div>
    );
};
