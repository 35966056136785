import dayjs from "dayjs";

export type LocalDate = string;

export const toLocalDate = (localDate: string): string => {
	return convertDayjsToLocalDate(dayjs(localDate));
}

export const convertDayjsToLocalDate = (date: dayjs.Dayjs): string => {
	return date.format('YYYY-MM-DD');
}
