import React from 'react';

interface TileHeaderProps {
    label: string;
}

export const TileHeader: React.FC<TileHeaderProps> = (props) => {
    const {label} = props;

    return <h2>{label}</h2>;
}
