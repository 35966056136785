import {WorkingDaysProfile, WorkingDaysProfileId} from "../workingDaysProfile/WorkingDaysProfile.type";
import {CalculationConfig} from "./CalculationConfig.type";

export const getWorkingDaysProfile = (config: CalculationConfig, id: WorkingDaysProfileId): WorkingDaysProfile => {
	const profile = config.workingDaysProfiles.find((profile) => profile.id === id);
	if (!profile) {
		throw new Error("Not found profile " + id);
	}
	return profile;
}
