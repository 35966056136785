import { ComeBarChart } from './ComeBarChart.component'
import { ComeLineChart } from './ComeLineChart.component'
import { ChartSeries, ChartType } from '../../domain/chart/Chart.type'

type ChartProps = {
    series: ChartSeries[],
    type: ChartType,
}

export const TransactionChart: React.FC<ChartProps> = (props: ChartProps) => {
  const {series, type} = props;
  
  var chart;
  switch (type) {
    case ChartType.LINE:
        chart = <ComeLineChart series={series}/>
        break;
        case ChartType.BARS:
        chart = <ComeBarChart series={series}/>
        break;
    default:
        chart = <></>
        break;
  }
  
  return chart;
}