import React from 'react';
import { AccountTree } from '../../../components/AccountTree/AccountTree.component';
import { AccountTreeNodeData } from '../../../components/AccountTree/AccountTree.type';
import { Account, AccountId } from '../../../domain/account/Account.types';
import { rootAccount } from '../../../domain/account/Accounts.constants';
import { SubAccounts } from '../../../domain/account/SubAccounts.type';
import { cashflowContext } from '../../../mian/CashflowContext/CashflowContext.util';

import styles from "./Dashboard.module.scss";


interface DashboardAccountsProps {

}

export const DashboardAccounts: React.FC<DashboardAccountsProps> = (props) => {
	
    const { accounts, calculationConfig } = React.useContext(cashflowContext);

	const accountTree = getAccountTree(rootAccount, calculationConfig.subAccounts, accounts);
	
	console.log(accountTree);
	

	return (
		<div className={styles.card}>
			<AccountTree root={accountTree}/>
		</div>
	);
};

const getAccountTree = (node: Account, subAccounts: SubAccounts, accounts: Account[]): AccountTreeNodeData => {
	const childrenAccountIds: AccountId[] = subAccounts[node.id] ?? [];

	console.log('node\'s children', node.id, childrenAccountIds);
	
	const childrenNodes = childrenAccountIds
		.map(childAccountId => accounts.find(account => account.id === childAccountId))
		.reduce((acu, it) => it ? [...acu, it] : acu, [] as Account[])
		.map(childAccount => getAccountTree(childAccount, subAccounts, accounts));

	return {
		id: node.id,
		label: node.label,
		children: childrenNodes,
	}
}