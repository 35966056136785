import { Account, AccountId } from "./Account.types";
import { rootAccountId } from "../../mian/__example__/ExampleAccount.constants";

export const WORLD: AccountId = 'WORLD';

const rootAccountLabel = 'Total'

//todo validate - it can not be removed
export const rootAccount: Account = {
	id: rootAccountId,
	label: rootAccountLabel,
};
