import React from 'react';

import styles from "./Dashboard.module.scss";
import {DashboardIndicator} from "./DashboardIndicator.component";


interface DashboardIndicatorsRowProps {

}

export const DashboardIndicatorsRow: React.FC<DashboardIndicatorsRowProps> = (props) => {

	return (
		<div className={styles.indicators}>
			<DashboardIndicator/>{/* total free days, left free days, used/behind free days */}
			<DashboardIndicator/>{/* current incomes, current outcomes, current savings - MOZLIWOSC DODAWANIA SWOICH */}
			<DashboardIndicator/>{/* total outcomes, month avg outcomes, total incomes, total avg outcomes */}
			<DashboardIndicator/>{/* not consumed amounts */}
			<DashboardIndicator/>{/* savings (total + per account) - ADD SAVINGS ACCOUNTS/GROUP */}
		</div>
	);
};
