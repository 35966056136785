import { ChartDataType, ChartType } from '../../domain/chart/Chart.type';
import { itAccount, rentalAccount } from '../../mian/__example__/ExampleAccount.constants';
import { rootAccount } from "../../domain/account/Accounts.constants";
import { CashflowContext } from "../CashflowContext/CashflowContext.type";
import { data as exampleData } from "../__example__/ExampleBudgetData.constants";

export const exampleContext: CashflowContext = {
    ...exampleData,
    selectedAccounts: [rootAccount],
    selectedYear: 2022,
    additionalViews: [
        {
            accounts: [rootAccount],
            combineAccountSeries: true,
            chartType: ChartType.BARS,
            dataType: ChartDataType.SEPARATED,
        },
        {
            accounts: [rootAccount],
            combineAccountSeries: true,
            chartType: ChartType.LINE,
            dataType: ChartDataType.TOTAL,
        },
        // {
        //     accounts: [rentalAccount, itAccount],
        //     combineAccountSeries: true,
        //     chartType: ChartType.BARS,
        //     dataType: ChartDataType.TOTAL,
        // },
    ]
};
