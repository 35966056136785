import { v4 as uuid } from 'uuid';
import {
	CyclicFrequency,
	CyclicTransaction,
	EntrySource,
	ExecutedTransaction,
	TaxModifier
} from "../../domain/transaction/Transaction.types";
import {WORLD} from "../../domain/account/Accounts.constants";
import {
	bigExpensesAccountId,
	carLoanAccountId,
	homeLoanAccountId,
	monthlyFeesAccountId
} from "./ExampleAccount.constants";



export const CarReturnTax: TaxModifier = {
	incomeTaxIncluded: true,
	incomeTax: 19,
	vatIncluded: true,
	vat: 11.5,
}
export const CompanyOutcomeReturnTax: TaxModifier = {
	incomeTaxIncluded: true,
	incomeTax: 19,
	vatIncluded: true,
	vat: 23,
}

export const cyclicOutcomes: CyclicTransaction[] = [
	{
		id: uuid(),
		label: 'ZUS',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		//todo check that comeGroup in each cyclicIncome is OUTCOME of ComeType
		value: 1500.,
		cyclicFrequency: CyclicFrequency.MONTHLY,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home fees',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		value: 2300.,
		cyclicFrequency: CyclicFrequency.MONTHLY,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home loan',
		accountFrom: homeLoanAccountId,
		accountTo: WORLD,
		value: 3000.,
		cyclicFrequency: CyclicFrequency.MONTHLY,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'car loan',
		accountFrom: carLoanAccountId,
		accountTo: WORLD,
		value: 2000.,
		cyclicFrequency: CyclicFrequency.MONTHLY,
		entrySource: EntrySource.CYCLIC,
	},
];
export const outcomesJanuary: ExecutedTransaction[] = [
	{
		id: uuid(),
		label: 'ZUS',
		date: '2022-01-01',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		value: 1500.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home fees',
		date: '2022-01-01',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		value: 2300.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home loan',
		date: '2022-01-01',
		accountFrom: homeLoanAccountId,
		accountTo: WORLD,
		value: 3000.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'car loan',
		date: '2022-01-01',
		accountFrom: carLoanAccountId,
		accountTo: WORLD,
		value: 2000.,
		tax: CarReturnTax,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'car fuel',
		date: '2022-01-01',
		accountFrom: carLoanAccountId,
		accountTo: WORLD,
		value: 450.,
		tax: CarReturnTax,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'company expense',
		date: '2022-01-01',
		accountFrom: bigExpensesAccountId,
		accountTo: WORLD,
		value: 9850.99,
		tax: CompanyOutcomeReturnTax,
		entrySource: EntrySource.CYCLIC,
	},
];

export const outcomesFebruary: ExecutedTransaction[] = [
	{
		id: uuid(),
		label: 'ZUS',
		date: '2022-02-01',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		value: 1500.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home fees',
		date: '2022-02-01',
		accountFrom: monthlyFeesAccountId,
		accountTo: WORLD,
		value: 2300.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'home loan',
		date: '2022-02-01',
		accountFrom: homeLoanAccountId,
		accountTo: WORLD,
		value: 3000.,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'car loan',
		date: '2022-02-01',
		accountFrom: carLoanAccountId,
		accountTo: WORLD,
		value: 2000.,
		tax: CarReturnTax,
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'car fuel',
		date: '2022-02-01',
		accountFrom: carLoanAccountId,
		accountTo: WORLD,
		value: 450.,
		tax: CarReturnTax,
		entrySource: EntrySource.MANUAL,
	},
];
