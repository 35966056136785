import {TransactionDate} from "./Transaction.types";
import {TransactionsMonthly} from "./TransactionMonthly.type";
import {getTransactionMonth} from "../date/Month.utils";
import {Month, MONTHS} from "../date/Month.constants";

export const createEmptyYearTransactionsMonthly = <T extends TransactionDate>(): TransactionsMonthly<T> => {
	return MONTHS.reduce(
		(acu: TransactionsMonthly<T>, month: Month) => ({...acu, [month]: []}),
		{} as TransactionsMonthly<T>
	);
}

export const groupTransactionsByMonth = <T extends TransactionDate>(comes: T[]): TransactionsMonthly<T> => {
	return comes.reduce((acu: TransactionsMonthly<T>, come: T) => {
		const month: Month = getTransactionMonth(come);
		const prevMonthOutcome = acu[month];
		// @ts-ignore todo enum-problem
		acu[month] = [...prevMonthOutcome, come];
		return acu;
	}, createEmptyYearTransactionsMonthly<T>());
}
