import React from 'react';
import { I18N } from '../../i18n';
import { TileHeader } from '../TileHeader/TileHeader.component';
import { AccountTreeNode } from './AccountTreeNode.component';
import { AccountTreeNodeData } from './AccountTree.type';

import classes from './AccountTree.module.scss';


interface AccountTreeProps {
    root: AccountTreeNodeData;
}

export const AccountTree: React.FC<AccountTreeProps> = (props) => {
    const {root} = props;

    return <>
        <TileHeader label={I18N.accounts.header}/>
        <div className={classes.accountsSection}>
            <AccountTreeNode node={root}/>
        </div>
    </>;
}
