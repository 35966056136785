import {ExecutedTransaction} from "../transaction/Transaction.types";

export const subtractVat = (value: number, vatPercent: number): number => {
	return value / (1 + vatPercent / 100.);
}
export const subtractIncomeTaxPercent = (value: number, incomeTaxPercent: number): number => {
	return value * (1 - incomeTaxPercent / 100.);
}

export const getNetUnit = (income: ExecutedTransaction): number => {
	const tax = income.tax;
	if (!tax) {
		return income.value;
	}
	const valueWithoutVat = tax.vatIncluded ? subtractVat(income.value, tax.vat) : income.value;
	return tax.incomeTaxIncluded ? subtractIncomeTaxPercent(valueWithoutVat, tax.incomeTax) : valueWithoutVat;
}
