import React from 'react';

import styles from "./Dashboard.module.scss";


interface DashboardIndicatorProps {

}

export const DashboardIndicator: React.FC<DashboardIndicatorProps> = (props) => {

	return (
		<div className={styles.indicatorCard}>
			<div className={styles.indicatorHead}>
				Incoming
			</div>
			<div className={styles.indicatorBody}>
				<div className={styles.indicatorValue}>
					10 400
				</div>
				<div className={styles.indicatorChart}>
					chart
				</div>
			</div>
		</div>
	);
};
