import dayjs from "dayjs";
import {Month, MONTHS} from "./Month.constants";
import {TransactionDate} from "../transaction/Transaction.types";
import {dayjsIndexToMonth} from "./DayjsMapper.constants";
import {convertDayjsToLocalDate} from "./LocalDate.types";

export const getTransactionMonth = <T extends TransactionDate>(come: T): Month => {
	// @ts-ignore todo enum-problem
	return dayjsIndexToMonth[dayjs(come.date).month()];
}

export const getNextMonth = (month: Month): Month => {
	// @ts-ignore
	return MONTHS[(MONTHS.indexOf(month) + 1) % 12];
}

export const getFirstDayOfMonthDayjs = (year: number, month: Month): dayjs.Dayjs => {
	const monthNumber = (MONTHS.indexOf(month) + 1);
	return dayjs(year + '-' + monthNumber + '-01');
}
export const getLastDayOfMonthDayjs = (year: number, month: Month): dayjs.Dayjs => {
	const firstDay = getFirstDayOfMonthDayjs(year, month);
	return firstDay.add(1, 'month').subtract(1, 'day');
}

export const getFirstDayOfMonth = (year: number, month: Month): string => {
	return convertDayjsToLocalDate(getFirstDayOfMonthDayjs(year, month));
}
export const getLastDayOfMonth = (year: number, month: Month): string => {
	return convertDayjsToLocalDate(getLastDayOfMonthDayjs(year, month));
}
