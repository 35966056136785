import React from 'react';
import { Dashboard } from './components/Dashboard.component';
import { cashflowContext } from '../CashflowContext/CashflowContext.util';
import { exampleContext } from '../__example__/ExampleInitContext.constants';


export const CashflowViewWrapper: React.FC = () => {

  // todo load from server or localStorage
  // const loaded = true;
  const contextValue = exampleContext;

  
  // if (!loaded) {
  //   return <LoaderWrCashflowViewWrapperer />;
  // }
  
  // retrievedContent={parsedContent}
  // queryParamsDraftId={queryParamsDraftId}

  return (
    <cashflowContext.Provider value={contextValue}>
        <Dashboard />
    </cashflowContext.Provider>
  );
}
