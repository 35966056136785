import _ from "lodash";
import {AccountId} from "./Account.types";
import {SubAccounts} from "./SubAccounts.type";

export const isSubAccountOfAnother = (account: AccountId, testedSubAccount: AccountId, subAccountsConfig: SubAccounts): boolean => {
	const subAccounts: AccountId[]|undefined = subAccountsConfig[account];
	if (!subAccounts) {
		return false;
	}
	return _.findIndex(subAccounts, (subAccount) => isAccountOrSubOfAnother(subAccount, testedSubAccount, subAccountsConfig)) > -1;
};

export const isAccountOrSubOfAnother = (account: AccountId, testedSubAccount: AccountId, subAccountsConfig: SubAccounts): boolean => {
	if (account === testedSubAccount) {
		return true;
	}
	return isSubAccountOfAnother(account, testedSubAccount, subAccountsConfig)
};
