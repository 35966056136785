import React from 'react';
import { AccountTreeNodeData } from './AccountTree.type';

import classes from './AccountTreeNode.module.scss';


interface AccountTreeNodeProps {
    node: AccountTreeNodeData;
}

export const AccountTreeNode: React.FC<AccountTreeNodeProps> = (props) => {
    const {node} = props;

    const nodes = node.children.map((childNode: AccountTreeNodeData) => {
        return <AccountTreeNode key={childNode.id} node={childNode}/>;
    })

    return <div>
        <div>{node.label}</div>
        <div className={classes.subAccountsSection}>
            {nodes}
        </div>
    </div>;
}
