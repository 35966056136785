import React from "react";
import { CashflowContext } from "./CashflowContext.type";

export const cashflowContext = React.createContext<CashflowContext>({
    calculationConfig: {
        workingDaysProfiles: [],
        subAccounts: {},
    },
    accounts: [],
    transactions: [],
    cyclicTransactions: [],
    selectedAccounts: [],
    selectedYear: 0,
    additionalViews: [],
});
