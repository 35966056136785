import React from 'react';
import {ExecutedTransaction} from "../../domain/transaction/Transaction.types";
import styles from "./TransactionEntry.module.scss";

interface TransactionEntryProps {
	transaction: ExecutedTransaction;
}

export const TransactionEntry: React.FC<TransactionEntryProps> = (props) => {
	const {transaction} = props;
	return (
		<div className={styles.root}>
			<span>{transaction.label}</span>
			<span>{transaction.value}</span>
		</div>
	);
};
