import {ExecutedTransaction, RenewalMetadata, RenewalType} from "./Transaction.types";
import {countBusinessDaysInMonth} from "../workingDaysProfile/WorkingDaysProfile.utils";
import {CalculationConfig} from "../calculationConfig/CalculationConfig.type";
import {getWorkingDaysProfile} from "../calculationConfig/CalculationConfig.utils";
import {requireNonNull} from "../utils/Objects.utils";
import {getNetUnit} from "../tax/Tax.utils";
import {getTransactionMonth} from "../date/Month.utils";
import {getTransactionYear} from "../date/Year.utils";

export const calcMonthRenewalValue = (config: CalculationConfig, transaction: ExecutedTransaction, netValue: number, renewal: RenewalMetadata): number => {
	const month = getTransactionMonth(transaction);
	const year = getTransactionYear(transaction);
	switch (renewal.type) {
		case RenewalType.WORK_DAY:
			const profile = getWorkingDaysProfile(config, requireNonNull(renewal.workingDaysProfileId));
			const businessDays = countBusinessDaysInMonth(year, month, profile);
			return netValue * businessDays;
		default:
			throw new Error('Renewal type is not supported to calc a month net: ' + renewal.type);
	}
}
export const calcMonthNet = (config: CalculationConfig, transaction: ExecutedTransaction): number => {
	const netValue = getNetUnit(transaction);
	const renewal = transaction.renewal;
	if (!renewal) {
		return netValue;
	}
	return calcMonthRenewalValue(config, transaction, netValue, renewal);
}

export const sumNetValues = (
	config: CalculationConfig,
	transactions: ExecutedTransaction[],
): number => {
	return transactions.reduce(
		(acu: number, transaction: ExecutedTransaction): number => acu + calcMonthNet(config, transaction),
		0.
	);
}
