import React from 'react';

import { cashflowContext } from '../../../mian/CashflowContext/CashflowContext.util';
import { TransactionList } from '../../../components/TransactionList/TransactionList.component';
import styles from "./Dashboard.module.scss";


interface DashboardTransactionListProps {
}

export const DashboardTransactionList: React.FC<DashboardTransactionListProps> = (props) => {
	
    const { transactions } = React.useContext(cashflowContext);
    // const [ formState, dispatch ] = React.useReducer(claimProcessReducer, retrievedContent);

	return (
		<div className={styles.card}>
			<h2>Transaction list</h2>


			<TransactionList
				transactions={transactions}
			/>
		</div>
	);
};
