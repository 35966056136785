import { AccountTransactionExpanse, AccountTransactionExpanseValues } from "../transaction/AccountTransactionExpanse.type";
import { I18N } from "./../../i18n";
import { ChartDataType, ChartSeries } from "./Chart.type";

const fixChartValue = (value: number): number => {
	return Number.parseFloat(value.toFixed(2));
}

const toOutcomeChartSeries = (label: string, accountTransactionExpanseValues: AccountTransactionExpanseValues[]): ChartSeries => {
	return {
		label: I18N.chart.label.outcomePrefix + label,
		data: accountTransactionExpanseValues.map((each): number => fixChartValue(each.outcome)),
	};
}
const toIncomeChartSeries = (label: string, accountTransactionExpanseValues: AccountTransactionExpanseValues[]): ChartSeries => {
	return {
		label: I18N.chart.label.incomePrefix + label,
		data: accountTransactionExpanseValues.map((each): number => fixChartValue((each.income))),
	};
}
const toTotalChartSeries = (label: string, accountTransactionExpanseValues: AccountTransactionExpanseValues[]): ChartSeries => {
	return {
		label: label,
		data: accountTransactionExpanseValues.map((each): number => fixChartValue((each.income - each.outcome))),
	};
}

const prepareChartSeriesFromAccountTransactionExpanseWithNoSeparateDataType = (accountTransactionExpanse: AccountTransactionExpanse, dataType: ChartDataType): ChartSeries => {
	switch (dataType) {
		case ChartDataType.TOTAL:
			return toTotalChartSeries(accountTransactionExpanse.account.label, accountTransactionExpanse.values);
		case ChartDataType.INCOMES:
			return toIncomeChartSeries(accountTransactionExpanse.account.label, accountTransactionExpanse.values);
		case ChartDataType.OUTCOMES:
			return toOutcomeChartSeries(accountTransactionExpanse.account.label, accountTransactionExpanse.values);
		default:
			throw new Error(`Unsupported chart data type: ${dataType}`);
	}
}

const prepareChartSeriesFromAccountTransactionExpanse = (accountTransactionExpanse: AccountTransactionExpanse, dataType: ChartDataType): ChartSeries[] => {
	if (dataType === ChartDataType.SEPARATED) {
		return [
			prepareChartSeriesFromAccountTransactionExpanseWithNoSeparateDataType(accountTransactionExpanse, ChartDataType.INCOMES),
			prepareChartSeriesFromAccountTransactionExpanseWithNoSeparateDataType(accountTransactionExpanse, ChartDataType.OUTCOMES),
		];
	}
	return [
		prepareChartSeriesFromAccountTransactionExpanseWithNoSeparateDataType(accountTransactionExpanse, dataType)
	];
}

export const prepareChartSeries = (accountTransactionExpanses: AccountTransactionExpanse[], dataType: ChartDataType): ChartSeries[] => {
	return accountTransactionExpanses.reduce((acu, item): ChartSeries[] => ([
		...acu,
		...prepareChartSeriesFromAccountTransactionExpanse(item, dataType),
	]), [] as ChartSeries[]);
}
