import React from 'react';
import { prepareYearAccountTransactionExpanses } from './../../../domain/transaction/AccountTransactionExpanse.utils';
import { TransactionChart } from '../../../components/TransactionChart/TransactionChart.component';
import { ChartDataType, ChartType } from './../../../domain/chart/Chart.type';
import { prepareChartSeries } from './../../../domain/chart/ChartSeries.utils';
import { cashflowContext } from './../../../mian/CashflowContext/CashflowContext.util';

interface DashboardChartProps {
}

const defaultChartView = {
    chartType: ChartType.BARS,
    dataType: ChartDataType.SEPARATED,
}

export const DashboardChart: React.FC<DashboardChartProps> = (props) => {
    const { calculationConfig, transactions, selectedYear, selectedAccounts } = React.useContext(cashflowContext);
    // const { viewState, setViewState } = React.useState(defaultChartView);
    const viewState = defaultChartView;

    console.warn('DashboardChart', transactions);


    //todo get data from context
    //todo calculate series by props
    //todo 

    //todo do it once, depends on accounts, transactions, config, balance
    const expanses = prepareYearAccountTransactionExpanses(calculationConfig, selectedAccounts, transactions, selectedYear);
    const series = prepareChartSeries(expanses, viewState.dataType);
    console.log('chart transactions: ', transactions);
    console.log('chart series: ', series);
    

    return (
        <TransactionChart
            series={series}
            type={viewState.chartType}
        />
    );
};
