import React from 'react';
import { prepareYearAccountTransactionExpanses } from '../../../domain/transaction/AccountTransactionExpanse.utils';
import { TransactionChart } from '../../../components/TransactionChart/TransactionChart.component';
import { prepareChartSeries } from '../../../domain/chart/ChartSeries.utils';
import { cashflowContext } from '../../../mian/CashflowContext/CashflowContext.util';

interface DashboardAdditionalChartListProps {
}

export const DashboardAdditionalChartList: React.FC<DashboardAdditionalChartListProps> = (props) => {

    const { calculationConfig, transactions, additionalViews, selectedYear } = React.useContext(cashflowContext);
    
    console.warn('DashboardChart', transactions);


    //todo update with accounts, transactions, config, balance
    const fcViews = additionalViews.map(view => {
        const expanses = prepareYearAccountTransactionExpanses(calculationConfig, view.accounts, transactions, selectedYear);
        const series = prepareChartSeries(expanses, view.dataType);

        return <TransactionChart
            series={series}
            type={view.chartType}
        />;
    });

    return <>
        {fcViews}
    </>;
};
