import React from 'react';

import styles from "./Dashboard.module.scss";


interface DashboardWorkingDaysProfilesProps {

}

export const DashboardWorkingDaysProfiles: React.FC<DashboardWorkingDaysProfilesProps> = (props) => {

	return (
		<div className={styles.card}>
			<h2>Working days profiles</h2>

			<span>
			DEFAULT:
			</span>
			<span>
			Work days: MON, TUE, WED, THU, FRI, SAT, SUN
			</span>
			<span>
			Public holidays:
			</span>
			<span>
			- 
			</span>
			<span>
			Sking:
			</span>
			<span>
			-
			</span>
			<span>
			-
			</span>
			<span>
			Africa:
			</span>
		</div>
	);
};
