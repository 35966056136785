import React from 'react';
import {ExecutedTransaction} from "../../domain/transaction/Transaction.types";
import {TransactionEntry} from "../TransactionEntry/TransactionEntry.component";

interface TransactionListProps {
	transactions: ExecutedTransaction[];
}

export const TransactionList: React.FC<TransactionListProps> = (props) => {
	const {transactions} = props;
	const elements = Array.from(transactions).map((transaction: ExecutedTransaction) => {
		return (
			<TransactionEntry
				key={transaction.id}
				transaction={transaction}
			/>
		);
	});

	return (
		<div>
			{elements}
		</div>
	);
}
