import {Account, AccountId} from "../../domain/account/Account.types";

export const rootAccountId: AccountId = 'rootAccountId';

export const pekaoAccountId: AccountId = 'pekaoAccountId';
export const milleniumAccountId: AccountId = 'milleniumAccountId';
export const rentalAccountId: AccountId = 'rentalAccountId';
export const itAccountId: AccountId = 'itAccountId';

export const monthlyFeesAccountId: AccountId = 'monthlyFeesAccountId';
export const homeLoanAccountId: AccountId = 'homeLoanAccountId';
export const carLoanAccountId: AccountId = 'carLoanAccountId';
export const bigExpensesAccountId: AccountId = 'bigExpensesAccountId';



export const pekaoAccount: Account = {
	id: pekaoAccountId,
	label: 'Private account - Pekao',
	parentAccountId: rootAccountId,
}
export const milleniumAccount: Account = {
	id: milleniumAccountId,
	label: 'Company - Millenium Bank',
	parentAccountId: rootAccountId,
}

export const rentalAccount: Account = {
	id: rentalAccountId,
	label: 'Private Rental',
	parentAccountId: rootAccountId,
};
export const itAccount: Account = {
	id: itAccountId,
	label: 'IT Services',
	parentAccountId: rootAccountId,
};

export const monthlyFeesAccount: Account = {
	id: monthlyFeesAccountId,
	label: 'Monthly Fees',
	parentAccountId: rootAccountId,
};
export const homeLoanAccount: Account = {
	id: homeLoanAccountId,
	label: 'Home Loan',
	parentAccountId: rootAccountId,
};
export const carLoanAccount: Account = {
	id: carLoanAccountId,
	label: 'Car Loan',
	parentAccountId: rootAccountId,
};
export const bigExpensesAccount: Account = {
	id: bigExpensesAccountId,
	label: 'Big Expenses',
	parentAccountId: rootAccountId,
};
