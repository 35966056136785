import dayjs from "dayjs";
import {WorkingDaysProfile} from "./WorkingDaysProfile.type";
import {Month} from "../date/Month.constants";
import {getFirstDayOfMonthDayjs, getLastDayOfMonthDayjs} from "../date/Month.utils";
import {convertDayjsToLocalDate} from "../date/LocalDate.types";

const isWorkingDay = (day: dayjs.Dayjs, profile: WorkingDaysProfile): boolean => {
	const weekDay = day.get('day');
	const localDate = convertDayjsToLocalDate(day);
	return profile.businessDays.includes(weekDay) &&
		!profile.publicHolidays.includes(localDate) &&
		!profile.personalHolidays.includes(localDate);
}

export const countBusinessDays = (from: dayjs.Dayjs, to: dayjs.Dayjs, profile: WorkingDaysProfile): number => {
	if (!from.isValid() || !to.isValid()) {
		throw new Error('date scope is invalid');
	}
	
	let counter = 0;
	let nextDay = from;
	while (!nextDay.isAfter(to)) {
		if (isWorkingDay(nextDay, profile)) {
			counter = counter + 1;
		}
		nextDay = nextDay.add(1, 'day');
	}
	
	return counter;
}

export const countBusinessDaysInMonth = (year: number, month: Month, profile: WorkingDaysProfile): number => {
	return countBusinessDays(
		getFirstDayOfMonthDayjs(year, month),
		getLastDayOfMonthDayjs(year, month),
		profile);
}
