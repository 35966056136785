export const holidays = [
	'2022-01-06',
	'2022-01-24',
	'2022-01-25',
	'2022-01-26',
	'2022-01-27',
	'2022-01-28',
	
	'2022-02-15',
	'2022-02-16',
	'2022-02-17',
	'2022-02-18',
	'2022-02-19',
	'2022-02-20',
	'2022-02-21',
	'2022-02-22',
	'2022-02-23',
	'2022-02-24',
	
	'2022-07-01',
	'2022-07-02',
	'2022-07-03',
	'2022-07-04',
	'2022-07-05',
	'2022-07-06',
	'2022-07-07',
	'2022-07-08',
	'2022-07-09',
	'2022-07-10',
	'2022-07-11',
	'2022-07-12',
	'2022-07-13',
	'2022-07-14',
	'2022-07-15',
	'2022-07-16',
	'2022-07-17',
	'2022-07-18',
	'2022-07-19',
	'2022-07-20',
	'2022-07-21',
	
	'2022-08-01',
	'2022-08-02',
	'2022-08-03',
	'2022-08-04',
	'2022-08-05',
	'2022-08-06',
	'2022-08-07',
	'2022-08-08',
	'2022-08-09',
	'2022-08-10',
	'2022-08-11',
	'2022-08-12',
	'2022-08-13',
	'2022-08-14',
	'2022-08-15',
	'2022-08-16',
	'2022-08-17',
	'2022-08-18',
	'2022-08-19',
	'2022-08-20',
	'2022-08-21',
	'2022-08-22',
	'2022-08-23',
	'2022-08-24',
	'2022-08-25',
	'2022-08-26',
	'2022-08-27',
	'2022-08-28',
	'2022-08-29',
	'2022-08-30',
	'2022-08-31',
	
	'2022-09-01',
	'2022-09-02',
	'2022-09-03',
	'2022-09-04',
	'2022-09-05',
	'2022-09-06',
	'2022-09-07',
	'2022-09-08',
	'2022-09-09',
	'2022-09-10',
	'2022-09-11',
	'2022-09-12',
	'2022-09-13',
	'2022-09-14',
	'2022-09-15',
	'2022-09-16',
	'2022-09-17',
	'2022-09-18',
	'2022-09-19',
	'2022-09-20',
	'2022-09-21',
	'2022-09-22',
	'2022-09-23',
	'2022-09-24',
	'2022-09-25',
	'2022-09-26',
	'2022-09-27',
	'2022-09-28',
	'2022-09-29',
	'2022-09-30',
];
