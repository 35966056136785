
export enum ChartType {
    LINE, BARS
}

export enum ChartDataType {
    TOTAL, SEPARATED, INCOMES, OUTCOMES
}

export interface ChartSeries {
	label: string;
	data: number[];
}
