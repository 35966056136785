export const I18N = {
    accounts: {
        header: 'Accounts/groups',
    },
    chart: {
        label: {
            incomePrefix: '[income] ',
            outcomePrefix: '[outcome] ',
        },
    },
}