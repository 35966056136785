import {Month} from "./Month.constants";
import {DayOfMonth} from "./DayOfMonth.constants";

export const monthToDayjsIndex = {
	[Month.JANUARY]: 0,
	[Month.FEBRUARY]: 1,
	[Month.MARCH]: 2,
	[Month.APRIL]: 3,
	[Month.MAY]: 4,
	[Month.JUNE]: 5,
	[Month.JULY]: 6,
	[Month.AUGUST]: 7,
	[Month.SEPTEMBER]: 8,
	[Month.OCTOBER]: 9,
	[Month.NOVEMBER]: 10,
	[Month.DECEMBER]: 11,
}

export const dayjsIndexToMonth = {
	0: Month.JANUARY,
	1: Month.FEBRUARY,
	2: Month.MARCH,
	3: Month.APRIL,
	4: Month.MAY,
	5: Month.JUNE,
	6: Month.JULY,
	7: Month.AUGUST,
	8: Month.SEPTEMBER,
	9: Month.OCTOBER,
	10: Month.NOVEMBER,
	11: Month.DECEMBER,
}

export const dayOfMonthToDayjsIndex = {
	[DayOfMonth.SUNDAY]: 0,
	[DayOfMonth.MONDAY]: 1,
	[DayOfMonth.TUESDAY]: 2,
	[DayOfMonth.WEDNESDAY]: 3,
	[DayOfMonth.THURSDAY]: 4,
	[DayOfMonth.FRIDAY]: 5,
	[DayOfMonth.SATURDAY]: 6,
}

export const dayjsIndexToDayOfMonth = {
	0: DayOfMonth.SUNDAY,
	1: DayOfMonth.MONDAY,
	2: DayOfMonth.TUESDAY,
	3: DayOfMonth.WEDNESDAY,
	4: DayOfMonth.THURSDAY,
	5: DayOfMonth.FRIDAY,
	6: DayOfMonth.SATURDAY,
}
