import {Month} from "../date/Month.constants";
import {TransactionDate} from "./Transaction.types";
import {getTransactionMonth} from "../date/Month.utils";
import {getTransactionYear} from "../date/Year.utils";


const isInMonth = <T extends TransactionDate>(transaction: T, month: Month): boolean => {
	return month === getTransactionMonth(transaction);
}

const isInYear = <T extends TransactionDate>(transaction: T, year: number): boolean => {
	return getTransactionYear(transaction) === year;
}

export const filterToMonthOfYear = <T extends TransactionDate>(transactions: T[], year: number, month: Month): T[] => {
	return transactions
		.reduce(
			(prev: T[], transaction: T) => (isInYear(transaction, year) && isInMonth(transaction, month) ? [...prev, transaction] : prev),
			[] as T[]);
};

export const filterToYear = <T extends TransactionDate>(transactions: T[], year: number): T[] => {
	return transactions
		.reduce(
			(prev: T[], transaction: T) => (isInYear(transaction, year) ? [...prev, transaction] : prev),
			[] as T[]);
};
