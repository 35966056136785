import React from 'react';
import {Bar} from 'react-chartjs-2';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';

import {ChartSeries} from "../../domain/chart/Chart.type";

import styles from "./TransactionChart.module.scss";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
        },
    },
};

const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const otherColors = [

    /*green*/
    'rgb(89,152,26)',
    'rgb(236,248,127)',
    'rgb(129,182,34)',
    'rgb(61,85,12)',
    'rgb(148,201,115)',
    'rgb(47,82,51)',
    'rgb(177,216,183)',
    'rgb(118,185,71)',

    /*red*/
    'rgb(255,127,80)',
    'rgb(255,93,34)',
    'rgb(243,65,0)',
    'rgb(197,53,0)',
    'rgb(151,41,0)',
    'rgb(106,28,0)',
    'rgb(60,16,0)',

    /*red/sinopia*/'rgba(199, 62, 29, 0.65)',
    /*green*/'rgba(42, 145, 52, 0.65)',
    /*satin sheen gold*/'rgba(197, 152, 73, 0.65)',
    /*celadon*/'rgba(166, 212, 159, 0.65)',

    'rgba(255, 99, 132, 0.65)',
    'rgba(53, 162, 235, 0.65)',
]

interface ComeBarChartProps {
    series: ChartSeries[],
}

export const ComeBarChart: React.FC<ComeBarChartProps> = (props: ComeBarChartProps) => {
    const {series} = props;

    const chartData = {
        labels: monthLabels,
        datasets: series.map((s, index) => ({
            label: s.label,
            data: s.data.map(value => Number.parseFloat(value.toFixed(2))),
            backgroundColor: otherColors[index],
        })),
    };

    return (
        <div className={styles.card}>
            Chart

            <Bar options={options} data={chartData}/>
        </div>
    );
};
