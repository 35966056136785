import {ExecutedTransaction, Transaction} from "./Transaction.types";
import {CalculationConfig} from "../calculationConfig/CalculationConfig.type";
import {Account, AccountId} from "../account/Account.types";
import {isAccountOrSubOfAnother} from "../account/SubAccounts.utils";
import {SubAccounts} from "../account/SubAccounts.type";
import {calcMonthNet} from "./TransactionsNetCalc.utils";
import { AccountTransactionExpanse, AccountTransactionExpanseValues } from "./AccountTransactionExpanse.type";
import { Month, MONTHS } from "../date/Month.constants";
import { groupTransactionsByMonth } from "./TransactionMonthly.utils";
import { filterToYear } from "./TransactionDateFilter.utils";
import { TransactionsMonthly } from "./TransactionMonthly.type";


const isTransferToAccount = (transaction: Transaction, account: AccountId, subAccountsConfig: SubAccounts): boolean => {
	return !isAccountOrSubOfAnother(account, transaction.accountFrom, subAccountsConfig)
		&& isAccountOrSubOfAnother(account, transaction.accountTo, subAccountsConfig);
};
const isTransferFromAccount = (transaction: Transaction, account: AccountId, subAccountsConfig: SubAccounts): boolean => {
	return !isAccountOrSubOfAnother(account, transaction.accountTo, subAccountsConfig)
		&& isAccountOrSubOfAnother(account, transaction.accountFrom, subAccountsConfig);
};

const emptyAccountTransactionExpanseValues = (): AccountTransactionExpanseValues => {
	return {
		income: 0.,
		outcome: 0.,
	};
}

const sumTransactionExpanseValues = (
	config: CalculationConfig,
	transactions: ExecutedTransaction[],
	account: AccountId,
): AccountTransactionExpanseValues => {
	return transactions.reduce(
		(acu: AccountTransactionExpanseValues, transaction: ExecutedTransaction): AccountTransactionExpanseValues => {
			if (isTransferToAccount(transaction, account, config.subAccounts)) {
				return {
					...acu,
					income: acu.income + calcMonthNet(config, transaction),
				};
			}
			if (isTransferFromAccount(transaction, account, config.subAccounts)) {
				return {
					...acu,
					outcome: acu.outcome + calcMonthNet(config, transaction),
				};
			}
			return acu;
		}, emptyAccountTransactionExpanseValues()
	);
}

const prepareYearAccountTransactionExpanseValues = (config: CalculationConfig, accountId: AccountId, transactionsByMonth: TransactionsMonthly<ExecutedTransaction>): AccountTransactionExpanseValues[] => {
	return MONTHS.reduce((acu, month: Month): AccountTransactionExpanseValues[] => {
		const monthTransaction: ExecutedTransaction[] | undefined = transactionsByMonth[month];
		const monthNetValue: AccountTransactionExpanseValues = monthTransaction ? sumTransactionExpanseValues(config, monthTransaction, accountId) : emptyAccountTransactionExpanseValues();
		return [...acu, monthNetValue];
	}, [] as AccountTransactionExpanseValues[]);
}

export const prepareYearAccountTransactionExpanses = (config: CalculationConfig, accounts: Account[], transactions: ExecutedTransaction[], year: number): AccountTransactionExpanse[] => {
	const transactionsByMonth = groupTransactionsByMonth(filterToYear(transactions, year));
	return accounts.reduce((acu, account): AccountTransactionExpanse[] => {
		const expanse = {
			account: account,
			values: prepareYearAccountTransactionExpanseValues(config, account.id, transactionsByMonth),
		};
		return [...acu, expanse];
	}, [] as AccountTransactionExpanse[]);
}

//todo remove it, previous version of sum
// const calcMonthNetForAccount = (config: CalculationConfig, transaction: ExecutedTransaction, account: AccountId): number => {
// 	if (isTransferToAccount(transaction, account, config.subAccounts)) {
// 		return calcMonthNet(config, transaction);
// 	}
// 	if (isTransferFromAccount(transaction, account, config.subAccounts)) {
// 		return (-1) * calcMonthNet(config, transaction);
// 	}
// 	return 0.;
// }
export const sumNetValuesForAccount = (
	config: CalculationConfig,
	transactions: ExecutedTransaction[],
	account: AccountId,
): number => {
	// return transactions.reduce(
	// 	(acu: number, transaction: ExecutedTransaction): number => acu + calcMonthNetForAccount(config, transaction, account),
	// 	0.
	// );
	const expansesValues = sumTransactionExpanseValues(config, transactions, account);
	return expansesValues.income - expansesValues.outcome;
}