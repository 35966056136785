import { v4 as uuid } from 'uuid';
import {EntrySource, TaxModifier, CyclicFrequency, RenewalType, CyclicTransaction, ExecutedTransaction} from "../../domain/transaction/Transaction.types";
import {WORLD} from "../../domain/account/Accounts.constants";
import {itAccountId, rentalAccountId} from "./ExampleAccount.constants";

export const incomeWorkingDaysProfileId = "defaultWorkingDays";

export const CompanyNetIncomeTax: TaxModifier = {
	incomeTax: 19,
	incomeTaxIncluded: true,
	vat: 23,
	vatIncluded: false,
}
export const CompanyGrossIncomeTax: TaxModifier = {
	incomeTax: 19,
	incomeTaxIncluded: true,
	vat: 23,
	vatIncluded: true,
}

export const cyclicIncomes: CyclicTransaction[] = [
	{
		id: uuid(),
		label: 'it company XYZ',
		//todo adding new holidays causes recalculate salary
		accountFrom: WORLD,
		accountTo: itAccountId,
		//todo check that comeGroup in each cyclicIncome is INCOME of ComeType
		value: 1000.,
		tax: CompanyNetIncomeTax,
		renewal: {
			type: RenewalType.WORK_DAY,
			workingDaysProfileId: incomeWorkingDaysProfileId,
		},
		entrySource: EntrySource.CYCLIC,
		cyclicFrequency: CyclicFrequency.MONTHLY,
	},
	{
		id: uuid(),
		label: 'rental',
		accountFrom: WORLD,
		accountTo: rentalAccountId,
		value: 2000.,
		tax: CompanyGrossIncomeTax,
		entrySource: EntrySource.CYCLIC,
		cyclicFrequency: CyclicFrequency.MONTHLY,
	}
];


export const generateIncomes = (date:string): ExecutedTransaction[] => ([
	{
		id: uuid(),
		label: 'it company XYZ',
		date: date,
		//todo first count work days in month, then calc all incomes values
		accountFrom: WORLD,
		accountTo: itAccountId,
		value: 1000.,
		//todo calc with vat, show vat value, show return vat value
		tax: CompanyNetIncomeTax,
		renewal: {
			type: RenewalType.WORK_DAY,
			workingDaysProfileId: incomeWorkingDaysProfileId,
		},
		entrySource: EntrySource.CYCLIC,
	},
	{
		id: uuid(),
		label: 'rental',
		date: date,
		accountFrom: WORLD,
		accountTo: rentalAccountId,
		value: 2000.,
		tax: CompanyGrossIncomeTax,
		entrySource: EntrySource.CYCLIC,
	},
]);
