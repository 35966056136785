import React from 'react';
import { DashboardAdditionalChartList } from './DashboardAdditionalChartList.component';
import { DashboardAccounts } from "./DashboardAccounts.component";
import { DashboardChart } from './DashboardChart.component';
import { DashboardTransactionList } from "./DashboardTransactionList.component";
import { DashboardIndicatorsRow } from "./DashboardIndicatorsRow.component";
import { DashboardWorkingDaysProfiles } from "./DashboardWorkingDaysProfiles.component";

import styles from "./Dashboard.module.scss";

interface DashboardProps {
}

/* todo add navbar
*
* wrapper
* 	content-page
* 		content
* 			navbar
* 			topnav shadow-sm
* 			container-fluid
* 				orw
* 					col-12
* 						page-title-box
*/

export const Dashboard: React.FC<DashboardProps> = (props) => {

	return (
		<div className={styles.dashboard}>
			<DashboardIndicatorsRow />
			<div className={styles.row}>
				<DashboardAdditionalChartList />
			</div>
			<div className={styles.row}>
				<div className={styles.column}>
					<DashboardAccounts />
					{/*business days, public holidays, user's holidays*/}
					<DashboardWorkingDaysProfiles />
				</div>
				<div className={styles.column}>
					<DashboardChart />
				</div>
			</div>
			<DashboardTransactionList />
		</div>
	);
};
