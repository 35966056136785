import {LocalDate} from "../date/LocalDate.types";
import {WorkingDaysProfileId} from "../workingDaysProfile/WorkingDaysProfile.type";
import {AccountId} from "../account/Account.types";

export enum EntrySource {
	CYCLIC,
	MANUAL,
}

export enum CyclicFrequency {
	WORK_DAY,
	WEEKLY,
	MONTHLY,
	YEARLY,
}

export enum RenewalType {
	WORK_DAY,
	//todo(optional) CUSTOM + defined custom day group
}

export interface RenewalMetadata {
	type: RenewalType;
	//todo if WORK_DAY then require workingDaysProfileId
	workingDaysProfileId?: WorkingDaysProfileId;
}

export interface TaxModifier {
	vat: number,
	vatIncluded: boolean,
	incomeTax: number,
	incomeTaxIncluded: boolean,
}

export interface TransactionDate {
	date: LocalDate;
}

export interface Transfer {
	accountFrom: AccountId,
	accountTo: AccountId,
}

export interface Transaction extends Transfer {
	id: string,
	label: string,
	value: number,
	tax?: TaxModifier,
	renewal?: RenewalMetadata,
	entrySource: EntrySource,
}

export interface ExecutedTransaction extends Transaction, TransactionDate {
	date: LocalDate;
}

export interface CyclicTransaction extends Transaction {
	cyclicFrequency: CyclicFrequency;
}

